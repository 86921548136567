import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DownloadApplicationComponent } from './download-application/download-application.component';
import { HomeComponent } from './home/home.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { TermsOfServiceComponent } from './terms-of-service/terms-of-service.component';




export const routes: Routes = [

  { path: '', component: HomeComponent, pathMatch: 'full' },
  { path:'download', component: DownloadApplicationComponent},
  { path:'terms-of-service', component: TermsOfServiceComponent},
  { path:'privacy-policy', component: PrivacyPolicyComponent},
  { path: '**', redirectTo: '/404' },


];



@NgModule(
  {
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]

  }
)
export class AppRoutingModule {

}