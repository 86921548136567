import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-download-application',
  templateUrl: './download-application.component.html',
  styleUrls: ['./download-application.component.scss']
})
export class DownloadApplicationComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }
  downloadWindows(){
//window.location.href = "https://app.furnituredesigner.online/assets/downloads/setup/FurnitureDesigner.WindowsSetup.msi";
  }

  navigateToApp(){
    window.open("https://app.furnituredesigner.online", "_blank");

  }
}
